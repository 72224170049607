import _export from "../internals/export";
import _stringMultibyte from "../internals/string-multibyte";
var $ = _export;
var codeAt = _stringMultibyte.codeAt; // `String.prototype.codePointAt` method
// https://tc39.es/ecma262/#sec-string.prototype.codepointat

$({
  target: "String",
  proto: true
}, {
  codePointAt: function codePointAt(pos) {
    return codeAt(this, pos);
  }
});
export default {};